import React from 'react';
import { Chip } from '@mui/material';
import { app_light, app_Light_bg, app_pink, app_pink_dark, app_pink_light, app_pink_light_bg, app_purple, COLORS } from '../../../data/constants';

interface PermanencyToggleProps {
    staffingPermanency: "perm" | "temp";
    setStaffingPermanency: (value: "perm" | "temp") => void;
}

const PermanencyToggle: React.FC<PermanencyToggleProps> = ({
    staffingPermanency,
    setStaffingPermanency,
}) => {
    const bgColor = staffingPermanency === "perm" ? app_Light_bg : app_pink_light_bg;
    const borderColor = staffingPermanency === "perm" ? app_purple : app_pink;

    return (
        <Chip
            label={staffingPermanency.toUpperCase()}
            onClick={() =>
                setStaffingPermanency(staffingPermanency === "perm" ? "temp" : "perm")
            }
            sx={{
                borderRadius: '16px',
                border: '1px solid ' + borderColor,
                cursor: 'pointer',
                backgroundColor: bgColor,
                color: COLORS.black,
                '&:hover': { opacity: 0.8 },
                float: 'right',
            }}
        />
    );
};

export default PermanencyToggle;
