import React from 'react';
import { format, parseISO } from 'date-fns';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Stack,
  Tooltip,
  Grid,
  Box
} from '@mui/material';
import {
  CalendarToday,
  AccessTime,
  Warning,
  Repeat,
  Check
} from '@mui/icons-material';
import TimeChip, { TimeRange } from './TimeChip';

interface ClashRow {
  clashEndsAtMinute: number;
  clashEndsAtMinuteOfWeek: number;
  clashStartsAtMinute: number;
  clashStartsAtMinuteOfWeek: number;
  clashTotalMinutes: number;
  existingMasterId: number;
  existingVisitId: number;
  permanency: string;
  seekingVisitId: number;
  visitDate: string;
  seekingVisitEndsAtMinuteOfWeek: number;
  seekingVisitStartsAtMinuteOfWeek: number;
  availStartsAtMinuteOfWeek: number;
  availEndsAtMinuteOfWeek: number;
}

interface GroupedClash {
  date: string;
  seekingVisitId: number;
  visitStartsAtMinute: number;
  visitEndsAtMinute: number;
  availStartsAtMinute: number;
  availEndsAtMinute: number;
  clashes: ClashRow[];
  hasPermanentClash: boolean;
  hasTemporaryClash: boolean;
}

const generateAvailabilityRanges = (
  availabilityWindows: TimeRange[],
  totalDuration: number,
  visitStart: number,
  cgHasAvailSchedule: boolean
): TimeRange[] => {
  // Sort windows by start time
  const sortedWindows = [...availabilityWindows]
    .sort((a, b) => a.start - b.start)
    .filter(window => window.end > window.start) // Remove invalid windows having same start and end
    .map(window => ({
      ...window,
      relativeStart: Math.max(0, window.start - visitStart),
      relativeEnd: Math.min(totalDuration, window.end - visitStart)
    }));

  const ranges = [];
  let currentPosition = 0;

  // Process each availability window
  for (const window of sortedWindows) {
    // Add error range if there's a gap before this window
    if (window.start > currentPosition) {
      ranges.push({
        start: currentPosition,
        end: window.relativeStart,
        relativeStart: currentPosition,
        relativeEnd: window.relativeStart,
        color: "error" //!!cgHasAvailSchedule ? "error" : "#e0e0e0"
      });
    }

    // Add the available range
    ranges.push({
      start: window.start,
      end: window.end,
      relativeStart: window.relativeStart,
      relativeEnd: window.relativeEnd,
      color: "success"
    });

    currentPosition = window.relativeEnd;
  }

  // Add final error range if there's remaining time
  if (currentPosition < totalDuration) {
    ranges.push({
      start: currentPosition,
      end: totalDuration,
      relativeStart: currentPosition,
      relativeEnd: totalDuration,
        color: "error" //!!cgHasAvailSchedule ? "error" : "#e0e0e0"
    });
  }

  return ranges.map(r => ({start: r.relativeStart, end: r.relativeEnd, color: r.color}));
};
const minutesToTime24 = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
};
const minutesToTime = (minutes: number): string => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    const period = hours >= 12 ? 'PM' : 'AM';
    const displayHours = hours % 12 || 12; // Convert 0 to 12 for 12 AM
    return `${displayHours}:${mins.toString().padStart(2, '0')} ${period}`;
  };

const generateTimeRanges = (visit: GroupedClash): TimeRange[] => {
    const ranges: TimeRange[] = [];
    const visitDuration = visit.visitEndsAtMinute - visit.visitStartsAtMinute;
    
    // Filter and transform clashes to be relative to visit start time
    const transformedClashes = visit.clashes
      .filter(clash => clash.clashTotalMinutes > 0)
      .map(clash => ({
        ...clash,
        // Adjust clash times to be relative to visit start (0-based)
        relativeStart: Math.max(0, clash.clashStartsAtMinute - visit.visitStartsAtMinute),
        relativeEnd: Math.min(visitDuration, clash.clashEndsAtMinute - visit.visitStartsAtMinute)
      }))
      .sort((a, b) => a.relativeStart - b.relativeStart);
  
    let currentPosition = 0;
  
    // Process each clash and the gaps between them
    for (let i = 0; i < transformedClashes.length; i++) {
      const clash = transformedClashes[i];
  
      // Add non-clash range before current clash if there's a gap
      if (currentPosition < clash.relativeStart) {
        ranges.push({
          start: currentPosition,
          end: clash.relativeStart,
          color: 'success'
        });
      }
  
      // Add the clash range
      ranges.push({
        start: clash.relativeStart,
        end: clash.relativeEnd,
        color: clash.permanency === 'Perm' ? 'error' : 'warning'
      });
  
      currentPosition = clash.relativeEnd;
    }
  
    // Add final non-clash range if there's remaining time
    if (currentPosition < visitDuration) {
      ranges.push({
        start: currentPosition,
        end: visitDuration,
        color: 'success'
      });
    }
  
    // Log for debugging
    // console.log('Visit duration:', visitDuration);
    // console.log('Transformed clashes:', transformedClashes);
    // console.log('Generated ranges:', ranges);
  
    return ranges;
};

const GroupedClashRow: React.FC<{
  group: GroupedClash;
  cgHasAvailSchedule?: boolean
}> = ({ group, cgHasAvailSchedule = false }) => {
  const formattedDate = `${format(parseISO(group.date), 'EEE')}, ${format(parseISO(group.date), 'MMM d, yyyy')}`;
  const visitTime = `${minutesToTime(group.visitStartsAtMinute)} - ${minutesToTime(group.visitEndsAtMinute)}`;
  const timeRanges = generateTimeRanges(group);
  const totalMinutes = group.visitEndsAtMinute - group.visitStartsAtMinute;
  const availRanges = generateAvailabilityRanges([{start: group.availStartsAtMinute, end: group.availEndsAtMinute}], totalMinutes, group.visitStartsAtMinute, cgHasAvailSchedule)

  //console.log('avail', group.availStartsAtMinute + " - " + group.availEndsAtMinute);
  //console.log('visit', group.visitStartsAtMinute + " - " + group.visitEndsAtMinute);
  //console.log('ranges', availRanges);

  const timeRanges1 = [
    {start: 0, end: 80, color: 'success'},
    {start: 80, end: 180, color: 'error'},
    {start: 180, end: 240, color: 'warning'},
    {start: 240, end: 300, color: 'success'},
  ]

  return (
    <>
      <TableRow sx={{ 
        
        //backgroundColor: 'grey.50'
       }}>
        <TableCell sx={{ py: 1.5, padding: '5px 10px', borderBottom: !!group?.clashes?.filter(c => c.clashTotalMinutes > 0)?.length ? 'none' : '0.5px solid grey.50' }}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={1}>
              <CalendarToday fontSize="small" sx={{ fontSize: 16 }} />
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2">{formattedDate}</Typography>
            </Grid>
            <Grid item xs={4.6}>
              <TimeChip
                totalMinutes={totalMinutes}
                timeRange={visitTime}
                upperRanges={timeRanges}
                lowerRanges={timeRanges}
                bottomRanges={availRanges}
              />
            </Grid>
            <Grid item>
              {group.hasPermanentClash ? (
                <Tooltip title="Has Permanent Clashes">
                  <Repeat color="error" sx={{ fontSize: 16 }} />
                </Tooltip>
              ) : group.hasTemporaryClash ? (
                <Tooltip title="Has Temporary Clashes">
                  <Warning color="warning" sx={{ fontSize: 16 }} />
                </Tooltip>
              ) : (
                <Tooltip title="No Clashes">
                  <Check color="success" sx={{ fontSize: 16 }} />
                </Tooltip>
              )}
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
      {group.clashes.filter(c => c.clashTotalMinutes > 0).map((clash, index) => (
        <TableRow key={index}>
          <TableCell sx={{ py: 1, padding: '5px 10px' }}>
            <Box sx={{ pl: 3.5 }}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={5.8}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <AccessTime sx={{ fontSize: 16, color: 'text.secondary' }} />
                    <Typography fontSize={13} variant="body2">
                      {minutesToTime(clash.clashStartsAtMinute)} - {minutesToTime(clash.clashEndsAtMinute)}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    variant="body2"
                    fontSize={13}
                    color={clash.permanency === 'Perm' ? 'error' : 'warning'}
                  >
                    {clash.permanency === 'Perm' ? 'Permanent' : 'Temporary'}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography 
                    fontSize={13}
                    variant="body2" color="text.secondary"
                  >
                    {clash.clashTotalMinutes} mins
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

const ClashTimeline: React.FC<{ clashes: ClashRow[], cgHasAvailSchedule?: boolean }> = ({ clashes, cgHasAvailSchedule = false }) => {
  const groupedClashes = React.useMemo(() => {
    const groups = new Map<string, GroupedClash>();
    
    clashes.forEach(clash => {
      const key = `${clash.visitDate}-${clash.seekingVisitId}`;
      // console.log('Debug values:', {
      //   visitStart: clash.seekingVisitStartsAtMinuteOfWeek % 1440,
      //   visitEnd: clash.seekingVisitEndsAtMinuteOfWeek % 1440,
      //   availStart: clash.availStartsAtMinuteOfWeek % 10080,
      //   availEnd: clash.availEndsAtMinuteOfWeek % 10080,
      // });
      const visitDay = Math.floor(clash.seekingVisitStartsAtMinuteOfWeek / 1440) * 1440;
      const availStartInDay = clash.availStartsAtMinuteOfWeek % 10080 - visitDay;
      const availEndInDay = clash.availEndsAtMinuteOfWeek % 10080 - visitDay;

      if (!groups.has(key)) {
        groups.set(key, {
          date: clash.visitDate,
          seekingVisitId: clash.seekingVisitId,
          visitStartsAtMinute: clash.seekingVisitStartsAtMinuteOfWeek % 1440,
          visitEndsAtMinute: clash.seekingVisitEndsAtMinuteOfWeek % 1440,
          availStartsAtMinute: Math.max(
            clash.seekingVisitStartsAtMinuteOfWeek % 1440,
            availStartInDay
          ),
          availEndsAtMinute: Math.min(
            clash.seekingVisitEndsAtMinuteOfWeek % 1440,
            availEndInDay
          ),
          clashes: [],
          hasPermanentClash: false,
          hasTemporaryClash: false,
        });
      }
      
      const group = groups.get(key)!;
      group.clashes.push(clash);
      if (clash.permanency === 'Perm' && clash.clashTotalMinutes > 0) {
        group.hasPermanentClash = true;
      } else if (clash.permanency === 'Temp' && clash.clashTotalMinutes > 0) {
        group.hasTemporaryClash = true;
      }

    //   const timeRanges : TimeRange[] = [{
    //     start: visitStartsAtMinute,
    //     end: clash.clashStartsAtMinute,
    //     color: 'success'  // Using MUI theme color
    //   },{
    //     start: clash.clashStartsAtMinute + 1,
    //     end: clash.clashEndsAtMinute + 1,
    //     color: 'error'  // Using MUI theme color
    //   },{
    //     start: clash.clashEndsAtMinute + 2,
    //     end: visitEndsAtMinute + 2,
    //     color: 'success'  // Using MUI theme color
    //   }];
    });
    
    return Array.from(groups.values());
  }, [clashes]);

  return (
    <TableContainer component={Paper} elevation={2}>
      <Table size="small">
        <TableBody>
          {groupedClashes
            .sort((a, b) => a.date > b.date ? 1 : -1)
            .map((group, index) => (
            <GroupedClashRow key={index} group={group} cgHasAvailSchedule={cgHasAvailSchedule} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ClashTimeline;