import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Card,
  CardContent,
  IconButton,
  Switch,
  FormControlLabel,
  Typography,
  Button,
  Chip,
  Alert,
} from '@mui/material';
import { Add, CheckCircle, Delete, Edit } from '@mui/icons-material';
import useFetcher, { FetcherHook } from '../../hooks/useFetcher';
import useToast from '../../hooks/useToast';
import { app_purple, app_xx_light_bg } from '../../data/constants';
import ConfirmationDialog from '../../components/ui/Modals/components/ConfirmationDailog';
import { PatientScheduleDto, PatientWeekScheduleDto } from '../schedules/components/Modals/PatientWeek/types';

interface ScheduleListItem {
  id: number;
  fromDate: string;
  toDate: string;
  activeDays: string;
  isCurrent: boolean;
}

interface PatientScheduleListProps {
  patientId: string;
  onScheduleSelect: (scheduleId: number | undefined) => void;
  scheduleFetcher: FetcherHook;
  newScheduleFetcher: FetcherHook;
  rowUpdated: boolean;
}

const PatientScheduleList: React.FC<PatientScheduleListProps> = ({
  patientId,
  onScheduleSelect,
  scheduleFetcher,
  newScheduleFetcher,
  rowUpdated
}) => {
  const [schedules, setSchedules] = useState<ScheduleListItem[]>([]);
  const [filteredSchedules, setFilteredSchedules] = useState<ScheduleListItem[]>([]);
  const [selectedScheduleId, setSelectedScheduleId] = useState<number | undefined>();
  const [showPastSchedules, setShowPastSchedules] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [scheduleToDelete, setScheduleToDelete] = useState<number | undefined>();
  const { get, delete: deleteSchedule, isLoading } = scheduleFetcher;
  const { get: createNew, isLoading: isLoadingNew } = newScheduleFetcher;
  const { showError, showSuccess } = useToast();

  const fetchSchedules = async () => {
    try {
      const response = await get(`schedule/patient/${patientId}/list`);
      const data = response?.data?.data;
      setSchedules(data || []);
      setFilteredSchedules(data || []);
      if (!selectedScheduleId && data?.length > 0) {
        handleScheduleSelect(data[0].id);
      }
    } catch (error) {
      showError('Failed to fetch schedules');
    }
  };

  useEffect(() => {
    fetchSchedules();
  }, [patientId, rowUpdated]);
  useEffect(() => {
    if (!isLoadingNew)
    fetchSchedules();
  }, [isLoadingNew]);

  useEffect(() => {
    if (showPastSchedules) {
      setFilteredSchedules(schedules);
    } else {
      const today = new Date();
      setFilteredSchedules(schedules.filter(schedule => 
        !schedule.toDate || new Date(schedule.toDate) >= today
      ));
    }
  }, [showPastSchedules, schedules]);

  const handleScheduleSelect = (scheduleId: number) => {
    setSelectedScheduleId(scheduleId);
    onScheduleSelect(scheduleId);
  };
  useEffect(() => {
    onScheduleSelect(selectedScheduleId)
  }, [selectedScheduleId])

  const handleDelete = async (scheduleId: number) => {
    setScheduleToDelete(scheduleId);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async (answer: boolean) => {
    if (answer && scheduleToDelete) {
      try {
        await deleteSchedule(`schedule/patient/${scheduleToDelete}`);
        showSuccess('Schedule deleted successfully');
        fetchSchedules();
      } catch (error) {
        showError('Failed to delete schedule');
      }
    }
    setDeleteDialogOpen(false);
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <FormControlLabel
            control={
              <Switch
                checked={showPastSchedules}
                onChange={(e) => setShowPastSchedules(e.target.checked)}
                size="small"
              />
            }
            label="Show past schedules"
          />
          <Button
            variant="outlined"
            startIcon={<Add />}
            onClick={async () => {
                setSelectedScheduleId(undefined)
                try {
                    var response = await createNew(`schedule/patient/${patientId}/new`);
                    const weekSchedule: PatientWeekScheduleDto = response.data.data;
                    showSuccess('Schedule created successfully');
                    fetchSchedules();
                    setSelectedScheduleId(weekSchedule.id)
                  } catch (error) {
                    showError('Failed to delete schedule');
                  }
            }}
            size="small"
          >
            New
          </Button>
        </Grid>

        <Grid item xs={12}>
          {  
            filteredSchedules.length === 0 ? (
            <Typography variant="body2" color="textSecondary" align="center">
              No schedules found
            </Typography>
          ) : (
            filteredSchedules.map((schedule) => (
              <Card
                key={schedule.id}
                sx={{
                  mb: 2,
                  cursor: 'pointer',
                  border: !!selectedScheduleId && selectedScheduleId === schedule.id ? '1px solid ' + app_purple : 'none',
                  position: 'relative',
                  backgroundColor: !!selectedScheduleId && selectedScheduleId === schedule.id ? app_xx_light_bg : 'white',
                }}
                onClick={() => handleScheduleSelect(schedule.id)}
              >
                {/* {selectedScheduleId === schedule.id && (
                  <CheckCircle 
                    sx={{ 
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      color: '#1976d2',
                      fontSize: 16
                    }} 
                  />
                )} */}
                <CardContent sx={{ p: 2, '&:last-child': { pb: 2 } }}>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Typography variant="subtitle1" component="div" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        {schedule.fromDate} - {schedule.toDate || 'Ongoing'}
                        {schedule.isCurrent && (
                          <Chip 
                            label="Active" 
                            color="success" 
                            size="small"
                            sx={{ height: 20 }}
                          />
                        )}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {schedule.activeDays}
                      </Typography>
                      {/* {schedule.isCurrent && (
                        <Alert severity="success" sx={{ mt: 1 }}>
                          Current Schedule
                        </Alert>
                      )} */}
                    </Grid>
                    <Grid item>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(schedule.id);
                        }}
                      >
                        <Delete fontSize="small" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ))
          )}
        </Grid>
      </Grid>
      
      <ConfirmationDialog
        open={deleteDialogOpen}
        onClose={handleDeleteConfirm}
        action="Delete"
        item="Schedule"
      />
    </Box>
  );
};

export default PatientScheduleList;