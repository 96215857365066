import React, { useEffect, useState } from 'react';
import { Box, Grid, Card, CardContent, Typography, Paper, IconButton, Button, Checkbox } from '@mui/material';
import ReactLoader from '../../../../../components/common/ReactLoader';
import useFetcher from '../../../../../hooks/useFetcher';
import { dateTime } from '../../../../../helper/datetime';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import Calendar from '../../../../../pages/Calendar/Calendar';
import { app_xxx_light_bg } from '../../../../../data/constants';
import { ClockIcon } from '@mui/x-date-pickers-pro';
import { MasterVisitCardProps, PatientDayDto, PatientWeekProps, VisitCardProps } from './types';

export const formatTime = (date: string) => {
    const time = new Date(date);
    return (time instanceof Date && !isNaN(time.getTime())) ? time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : 'N/A';
};

export const MasterVisitCard: React.FC<MasterVisitCardProps> = ({ key, master, visit, selected, handleClick, showScheduledInfo = true }) => {
    return (
        <Box>
            <Card key={key} sx={{ backgroundColor: 'grey.100', marginBottom: 1, padding: 0 }}>
                <CardContent
                    sx={{
                        padding: '2px 2px 2px 3px',
                        '&:last-child': { paddingBottom: '2px' }
                    }}>
                    <Typography variant="caption" sx={{ display: 'block' }}>
                        {/* <strong>Master Caregiver:</strong> */}
                        {/* <Checkbox
                            //checked={multiSelection.hasValue(visit.visitId?.toString() ?? '0')}
                            checked={selected}
                            onTouchMove={(e) => {
                                e.stopPropagation();
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            onChange={(e) => {
                                e.stopPropagation();
                                if (!!handleClick)
                                    handleClick();
                                // console.log('before', multiSelection.selected);
                                // if (visit.visitId !== undefined) {
                                //     multiSelection.toggleItem(visit.visitId?.toString());
                                // }
                            }}
                            sx={{
                                '& .MuiSvgIcon-root': { fontSize: 16 },
                                marginTop: '1px',
                                padding: 0,
                                paddingTop: '0px',
                                float: 'right'
                            }}
                        /> */}
                        <Typography fontSize="12px">{!!master.masterCaregiver ? master.masterCaregiver.name : 'N/A'}</Typography>
                    </Typography>
                    <Typography variant="caption">{!!master.masterStartTime ? formatTime(new Date(master.masterStartTime).toString()) : 'N/A'}</Typography>
                    <Typography variant='caption'> - </Typography>
                    <Typography variant="caption">{!!master.masterEndTime ? formatTime(new Date(master.masterEndTime).toString()) : 'N/A'}</Typography>
                </CardContent>
            </Card>
            {!!visit && !!showScheduledInfo &&
                <VisitCard visit={visit} key={key} />
            }
        </Box>
    )
}
export const VisitCard: React.FC<VisitCardProps> = ({ visit, onCheckboxChange }) => {
    return (
        <Card sx={{ marginBottom: 1, padding: 0 }}>
            <CardContent
                sx={{
                    padding: '2px 2px 2px 3px',
                    '&:last-child': { paddingBottom: '2px' },
                }}
            >
                <Typography variant="caption" sx={{ display: 'block' }}>
                    <strong>Scheduled:</strong>
                    <Typography fontSize="12px">
                        {!!visit.scheduleCaregiver ? visit.scheduleCaregiver.name : 'N/A'}
                    </Typography>
                </Typography>
                <Typography variant="caption">
                    {visit.scheduleStartTime ? formatTime(visit.scheduleStartTime.toString()) : 'N/A'}
                </Typography>
                <Typography variant="caption"> - </Typography>
                <Typography variant="caption">
                    {visit.scheduleEndTime ? formatTime(visit.scheduleEndTime.toString()) : 'N/A'}
                </Typography>
                <Typography variant="caption" sx={{ display: 'block' }}>
                    <ClockIcon sx={{ fontSize: '13px' }} />
                    {!!visit.clockInTime
                        ? formatTime(new Date(visit.clockInTime).toString())
                        : 'N/A'}{' '}
                    -{' '}
                    {!!visit.clockOutTime
                        ? formatTime(new Date(visit.clockOutTime).toString())
                        : 'N/A'}
                </Typography>
                {visit.isMissedVisit ? (
                    <Typography
                        variant="caption"
                        sx={{ display: 'block' }}
                        style={{ color: 'red' }}
                    >
                        Missed
                    </Typography>
                ) : (
                    <br />
                )}
            </CardContent>
        </Card>
    );
};
