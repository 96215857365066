import React, { useEffect, useState } from 'react';
import { Box, Grid, Card, CardContent, List, ListItem, Divider, Typography, Paper, IconButton, Button } from '@mui/material';
import ReactLoader from '../../../../../components/common/ReactLoader';
import useFetcher from '../../../../../hooks/useFetcher';
import { UnderlinedTitle } from '../../../../../assets/styles/styledComponents';
import { dateTime } from '../../../../../helper/datetime';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import Calendar from '../../../../../pages/Calendar/Calendar';
import { app_Light_bg, app_pink_dark, app_pink_light, app_pink_light_bg, app_xxx_light_bg } from '../../../../../data/constants';

type CaregiverWeekProps = {
    caregiverId: number;
    weekDates: [Date, Date];
};

type VisitDto = {
    visitId: number;
    hhaVisitId: number;
    visitDate?: string;
    scheduleStartTime: Date;
    scheduleEndTime: Date;
    patientId?: number;
    caregiverId?: number;
    patientName?: string;
    caregiverName?: string;
    patientAddress?: string;
    caregiverAddress?: string;
    hhaPatientId?: number;
    hhaCaregiverId?: number;
    isMasterVisit?: boolean;
};

type WorkWindow = {
    dateFrom?: string;
    dateTo?: string;
    id: number;
    isDeleted: boolean;
    isPredicted: boolean;
    note?: string;
    prefLevel?: string;
    remindXDaysBeforeEnds?: number;
    timeFrom?: string;
    timeTo?: string;
    weekDay?: string;
};

type CaregiverDayDto = {
    dayDate: Date;
    dayOfWeek: string;
    visits: VisitDto[];
    workWindows: WorkWindow[];
};

const CaregiverWeek: React.FC<CaregiverWeekProps> = ({ caregiverId, weekDates }) => {
    const [weekData, setWeekData] = useState<CaregiverDayDto[]>([]);
    const [showFull, setShowFull] = useState(false);
    const [dates, setDates] = useState<[Date, Date]>();
    const [maxWindows, setMaxWindows] = useState(0);
    const fetcher = useFetcher();

    const formatTime = (date: string) => {
        const time = new Date(date);
        return (time instanceof Date && !isNaN(time.getTime())) ? time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : 'N/A';
    };

    const getWeekData = (sentDate: Date) => {
        const date = sentDate instanceof Date ? sentDate : new Date(sentDate);

        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;

        fetcher.get(`schedule/caregiver/by_week/${caregiverId}/${formattedDate}`).then((response) => {
            setWeekData(response.data.data);
            const max = Math.max(...response.data.data.map((d: CaregiverDayDto) => d.workWindows.length))
            setMaxWindows(max);
        })
    }

    useEffect(() => {
        if (fetcher.isLoading)
            return;

        if (!weekDates?.length)
            return;

        getWeekData(weekDates[0])
    }, [caregiverId, dates]);

    useEffect(() => {
        if (!weekDates?.length)
            return;

        console.log(weekDates);

        if (dates == undefined || (new Date(weekDates[0])).toDateString() != (new Date(dates[0])).toDateString() || (new Date(weekDates[1])).toDateString() != (new Date(dates[1])).toDateString()) {
            setDates(weekDates)
        }

    }, [weekDates])

    const handleClickNext = () => {
        const date = new Date(weekData[6]?.dayDate);
        // if (date < weekDates[1]) {
        date.setDate(date.getDate() + 1)
        getWeekData(date)
        // }
    }

    const handleClickBack = () => {
        const date = new Date(weekData[0]?.dayDate)
        // if (date > weekDates[0]) {
        date.setDate(date.getDate() - 1)
        getWeekData(date)
        // }
    }

    const visitDatesIsBefore = () => {
        const date = new Date(weekData[0]?.dayDate)
        return date > weekDates[0]
    }

    const visitDatesIsAfter = () => {
        const date = new Date(weekData[6]?.dayDate)
        return date < weekDates[1]
    }

    return (
        <Paper style={{ maxWidth: 'none !important' }}>
            {showFull &&
                <Box>
                    <Box>
                        <Calendar passedDate={weekDates[0]} module='caregiver' id={caregiverId.toString()} dateBoxHeight='100px' width='1300px' showCaregiver />
                    </Box>
                    <div style={{ float: 'right', margin: '20px', bottom: '15px' }}>
                        <Button size='small' onClick={() => setShowFull(!showFull)}>
                            {showFull ? 'See Week Details' : 'See Full Calendar'}
                        </Button>
                    </div>
                </Box>
            }
            {!showFull && <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
                <IconButton
                    color='primary' onClick={() => handleClickBack()}
                    sx={{
                        borderRadius: '0px',
                        backgroundColor: app_xxx_light_bg,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: 0,
                        marginLeft: 0,
                        width: 30,
                    }}>
                    <ArrowBackIos sx={{
                        margin: 0,
                        verticalAlign: 'middle',
                    }} />
                </IconButton>
                <Box sx={{ padding: '5px', minWidth: '1200px', minHeight: '170px' }}>
                    <ReactLoader isLoading={fetcher.isLoading} />
                    <Grid container spacing={0.5} direction="row">
                        {weekData.map((day, i) => (
                            <Grid item xs key={i}>
                                <Typography color="primary" variant="caption" sx={{ display: 'block', padding: 0 }}>
                                    {day.dayOfWeek}{' '}{dateTime.getShortMonthAndDay(day.dayDate)}
                                </Typography>
                                {day.workWindows.map((window, j) => (
                                    <Card sx={{ backgroundColor: 'grey.100', marginBottom: 1, padding: 0 }}>
                                        <CardContent sx={{
                                            backgroundColor: window.isPredicted == true ? 'rgba(120, 70, 255, 0.08);' : '',
                                            padding: '2px 2px 2px 3px',
                                            '&:last-child': { paddingBottom: '2px' }
                                        }}>
                                            <Typography variant="caption" sx={{ display: 'block' }}>
                                                {window.timeFrom ? formatTime(window.timeFrom) : 'N/A'}{' - '}
                                                {window.timeTo ? formatTime(window.timeTo) : 'N/A'}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                ))}
                                {Array(maxWindows - day.workWindows?.length).fill(null).map((item, index) => {
                                    return (
                                        <Card sx={{ backgroundColor: 'grey.100', marginBottom: 1, padding: 0 }}>
                                            <CardContent sx={{
                                                padding: '2px 2px 2px 3px',
                                                '&:last-child': { paddingBottom: '2px' }
                                            }}>
                                                <Typography variant="caption" sx={{ display: 'block' }}>
                                                    -
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    )
                                })}
                                {day.visits.map((visit, j) => (
                                    <Card key={j} sx={{ marginBottom: 1, padding: 0 }}>
                                        <CardContent
                                            sx={{
                                                padding: '2px 2px 2px 3px',
                                                '&:last-child': { paddingBottom: '2px' }
                                            }}
                                        >
                                            <div>
                                                <Typography variant="caption">
                                                    <UnderlinedTitle fontSize="12px">{visit.patientName}</UnderlinedTitle>
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography variant="caption">{visit.scheduleStartTime ? formatTime(visit.scheduleStartTime.toString()) : 'N/A'}</Typography>
                                                <Typography variant='caption'> - </Typography>
                                                <Typography variant="caption">{visit.scheduleEndTime ? formatTime(visit.scheduleEndTime.toString()) : 'N/A'}</Typography>
                                            </div>
                                            <div style={{ color: visit.isMasterVisit === true ? app_Light_bg : app_pink_dark }}>
                                                <Typography variant="caption">
                                                    <strong>
                                                        {visit.isMasterVisit === true ? ("Master" + (!!visit.visitId ? "" : " - Not scheduled")) : "Temporary"}
                                                    </strong></Typography>
                                            </div>
                                            {/* Add more fields */}
                                        </CardContent>

                                    </Card>
                                ))}
                            </Grid>
                        ))}
                    </Grid>
                    <div style={{ position: 'absolute', right: '40px', bottom: '15px' }}>
                        <Button size='small' onClick={() => setShowFull(!showFull)}>
                            {showFull ? 'See Week Details' : 'See Full Calendar'}
                        </Button>
                    </div>
                </Box>
                <IconButton
                    color='primary' onClick={() => handleClickNext()}
                    sx={{
                        borderRadius: '0px',
                        backgroundColor: app_xxx_light_bg,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: 0,
                        marginRight: 0,
                        width: 30,
                    }}>
                    <ArrowForwardIos sx={{
                        margin: 0,
                        verticalAlign: 'middle',
                    }} />
                </IconButton>
            </div>}
        </Paper>
    );

};

export default CaregiverWeek;