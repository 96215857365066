export const homePageUrl = '/patients';
export const loginPageUrl = '/login';
export const LOCAL_URL = "https://localhost:7007/";
export const STAGING_URL = "https://api.connectaide.ai/";
export const TEST_URL = "https://carematchserver-dev.azurewebsites.net/";
export const MATCHING_URL = "https://match-algo.azurewebsites.net/"
export const CLIENT_APP_VERSION = "1.0.1.22";
export const BASE_URL = STAGING_URL;
export const MODES = [
    { name: 'debug', url: LOCAL_URL },
    { name: 'prod', url: STAGING_URL },
    { name: 'test', url: TEST_URL }
]
export const LOGIN_PATH = "api/auth/login";
export const RESET_PASS_PATH = "api/auth/reset-password";

export const MAPBOX_TOKEN = "pk.eyJ1IjoiYXNocmF5Ym1hbiIsImEiOiJjbGtiaWZhc3AwN2UwM2dzOTU4MHBvODdlIn0.78PpNm5-g48fpA8Tss5FWg";

export const PUBLIC_APP_URL_LIVE = "https://carematchpublic.azurewebsites.net/";
export const PUBLIC_APP_URL_DEV = "http://localhost:5240/";

export const SAVE_USER_PATH = "api/Users/save";
export const SEND_RESET_PASSWORD_LINK = "api/Users/send-reset-password/";
export const DELETE_USER_PATH = "api/Users/delete";
export const USER_REGISTER_IP_PATH = "api/users/register";

export const appModules = {
    patient: 'patient',
    caregiver: 'caregiver'
}
export const PATIENT_DETAIL = "/patient/:id/details";
export const PATIENT_DELETE = "/patient/delete/:id";
export const PROVIDER_DETAIL = "/provider/:id/details";

export const explorerTypesConst = {
    patient: 'patient',
    provider: 'provider'
}

export const reactNetworkDetailOptions = [
    { label: 'url', value: 'url' },
    { label: 'status', value: 'status' },
    { label: 'response', value: 'response' },
    { label: 'name', value: 'name' },
    { label: 'statusMessage', value: 'statusMessage' }
]

export const ExplorerTabs = [
    {
        label: 'General',
        value: 'general',
        isError: false
    },
    // {
    //     label: 'Preference',
    //     value: 'oldPreference',
    //     isError: false
    // },
    {
        label: 'Preferences',
        value: 'preference',
        isError: false
    },
    {
        label: 'Visits',
        value: 'visits',
        isError: false
    },
    {
        label: 'Availability',
        value: 'availability',
        isError: false
    },
    {
        label: 'Schedule',
        value: 'schedule',
        isError: false
    },
    {
        label: 'Patients',
        value: 'patients',
        isError: false
    },
    {
        label: 'Caregivers',
        value: 'caregivers',
        isError: false
    },
    // {
    //     label: 'Declined',
    //     value: 'declined',
    //     isError: false
    // },
];

export const TaskTabs = [
    {
        label: 'Active',
        value: 'tasks',
        isError: false
    },
    {
        label: 'Completed',
        value: 'completed',
        isError: false
    },
];

export const FillTabs = [
    {
        label: 'List View',
        value: 'list',
        isError: false
    },
    {
        label: 'Map View',
        value: 'map',
        isError: false
    },
];

export const MODAL_TYPE = {
    patientForm: 'patientForm',
    confirmDialog: 'confirmDailog'
}

export const COAI: string[] = [
    'coai',
    'referral',
    'candidate',
    '',
];

export const weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];
export const CUSTOM_SCROLL = {
    '&::-webkit-scrollbar': {
        width: '5px',
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'lightgrey',
        borderRadius: '6px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: 'gray',
    },
};

export const CUSTOM_THIN_SCROLL = {
    '&::-webkit-scrollbar': {
        width: '3px',
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'lightgrey',
        borderRadius: '6px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: 'gray',
    },
};

export const getStatusColor = (status: string) => {
    switch (status) {
        case 'Assigned':
            return 'darkgreen';
        case 'Taken':
            return 'lightblue';
        case 'Pending':
            return app_purple;
        case 'Accepted':
            return 'green';
        case 'Declined':
            return 'orange';
        default:
            return 'info';
    }
}

export const StatusOptions = [
    'Pending',
    'Accepted',
    'Declined',
]

export const app_purple = '#7846ff';
export const app_lighter = '#a584ff';
export const app_light = '#d2c1ff';
export const app_pink_light = '#fdc1ff';
export const app_pink = '#f952ff';
export const app_pink_dark = '#db2ce1';
export const app_Light_bg = '#ded1ff';
export const app_pink_light_bg = '#fdd1ff';
export const app_red_light_bg = '#ffd1d1';
export const app_blue_light_bg = '#d1daff';
export const app_green_light_bg = '#cff0ba';
export const app_x_light_bg = '#e8deff';
export const app_xx_light_bg = '#efe8ff';
export const app_xxx_light_bg = '#f4f0ff';

export const COLORS = {
    white: '#FFF',
    black: '#000',
    disabled: '#CCC'
}