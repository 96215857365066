import React, { useEffect, useState } from 'react';
import { Box, Grid, Card, CardContent, Typography, Paper, IconButton, Button, Checkbox, TextField, FormControl, MenuItem, Select, InputLabel } from '@mui/material';
import ReactLoader from '../../../../../components/common/ReactLoader';
import useFetcher from '../../../../../hooks/useFetcher';
import { dateTime } from '../../../../../helper/datetime';
import { ArrowBackIos, ArrowForwardIos, CalendarToday, ExpandLessOutlined, ExpandLessRounded, ExpandMoreOutlined, KeyboardArrowUp } from '@mui/icons-material';
import Calendar from '../../../../../pages/Calendar/Calendar';
import { app_light, app_Light_bg, app_pink_light, app_xx_light_bg, app_xxx_light_bg, COLORS } from '../../../../../data/constants';
import { ClockIcon } from '@mui/x-date-pickers-pro';
import { MasterVisitCardProps, PatientDayDto, PatientWeekProps, PatientWeekScheduleDto, VisitCardProps } from './types';
import { MasterVisitCard, VisitCard } from './components';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Title } from '../../../../../assets/styles/styledComponents';

const getDateBoundary = (dates: Date[], type: 'earliest' | 'latest'): Date | null => {
    if (!dates.length) return null;

    return dates.reduce((boundary, current) => {
        if (!current) return boundary;
        if (!boundary) return current;
        return type === 'earliest' ?
            (current < boundary ? current : boundary) :
            (current > boundary ? current : boundary);
    });
};

const getEarliestDate = (dates: Date[]): Date | null => {
    return getDateBoundary(dates, 'earliest');
};

const getLatestDate = (dates: Date[]): Date | null => {
    return getDateBoundary(dates, 'latest');
};

type DateEditFieldProps = {
    dateValue: string;
    label: string;
    handleChange: (value: string) => void;
    maxDate?: Date;
    minDate?: Date;
}

const DateEditField: React.FC<DateEditFieldProps> = ({ dateValue, label, handleChange, minDate = null, maxDate = null }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TextField
                label={label}
                type="date" // Use HTML date input format
                value={dateValue || ""}
                onChange={(e: any) => handleChange(e.target.value)}
                fullWidth
                InputLabelProps={{ shrink: true }}
                size="small"
                InputProps={{
                    endAdornment: (
                        <IconButton
                            onClick={() => {
                                document.getElementById("begin-picker")?.click();
                            }}
                        >
                            <CalendarToday fontSize="small" />
                        </IconButton>
                    ),
                }}
                sx={{
                    "& input::-webkit-calendar-picker-indicator": {
                        display: "none",
                        paddingRight: '0px'
                    },
                    "& input[type='date']": {
                        "-webkit-appearance": "none",
                        "-moz-appearance": "none",
                        appearance: "none",
                        fontSize: '15px',
                        height: '15px', // Makes the input field smaller
                        padding: '3px 0px 3px 10px', // Adjust padding for smaller input
                    },
                }}
            />
            <MobileDatePicker
                value={dateValue ? dayjs(dateValue).format('YYYY-MM-DD') : null}
                minDate={minDate}
                onChange={(value: any) => handleChange(dayjs(value).format('YYYY-MM-DD'))}
                renderInput={(params: any) => (
                    <input
                        {...params.inputProps}
                        id="begin-picker"
                        style={{ display: "none" }} // Hide the picker input
                    />
                )}
            />
        </LocalizationProvider>
    )
}
// Hardcoded date ranges
const dateRanges = [
    {
        label: 'Range 1',
        fromDate: '2023-06-01',
        toDate: '2023-09-01',
    },
    {
        label: 'Range 2',
        fromDate: '2023-09-01',
        toDate: '2023-12-01',
    },
    {
        label: 'Range 3',
        fromDate: '2023-12-01',
        toDate: '2024-04-30',
    },
];

export type PatientWeekSeekProps = {
    patientId: number;
    weekDates: [Date, Date];
    showScheduledInfo?: boolean;
    selectedMasterDaysIDs: number[];
    toggleItem?: (id: string) => void;
    staffingAs?: 'temp' | 'perm';
    set_staffingAs?: any;
};
const PatientWeekSeek: React.FC<PatientWeekSeekProps> = ({ patientId, weekDates, showScheduledInfo = true, selectedMasterDaysIDs, toggleItem
    , staffingAs = 'temp', set_staffingAs = () => { } }) => {
    const [weekData, setWeekData] = useState<PatientDayDto[]>([]);
    const todayDT = new Date();
    const todayString = dayjs(todayDT).format('YYYY-MM-DD')
    const fourWeekFromNow = dayjs(todayDT).add(4, 'week').format('YYYY-MM-DD');
    const [fromDate, set_fromDate] = useState<string>(todayString);
    const [toDate, set_toDate] = useState<string>(fourWeekFromNow);
    const [isRecurring, set_isRecurring] = useState<boolean>(true);
    const [expandRecurring, set_expandRecurring] = useState<boolean>(true);
    const fetcher = useFetcher();
    const scheduleFetcher = useFetcher();

    const [masterWeeks, setMasterWeeks] = useState<PatientWeekScheduleDto[]>([]);
    const [selectedMasterWeeksIDs, setSelectedMasterWeeksIDs] = useState<number[]>([]);
    const fetchMasterWeeks = async () => {
        try {
            const response = await scheduleFetcher.get(`schedule/patient/${patientId}/list`);
            const data = response?.data?.data;
            setMasterWeeks(data || []);

            if ((!!data && data.length > 0) && (!!selectedMasterWeeksIDs && selectedMasterWeeksIDs.length < 1))
                setSelectedMasterWeeksIDs([data[0].id]);
        } catch (error) {
            //showError('Failed to fetch schedules');
        }
    };
    useEffect(() => {
        fetchMasterWeeks();
    }, [patientId])
    useEffect(() => {
        set_isRecurring(staffingAs == 'perm');
    }, [])

    useEffect(() => {
        console.log(isRecurring);
    }, [isRecurring])
    useEffect(() => {
        if (!!masterWeeks?.length)
            set_expandRecurring(true)
        else
            set_expandRecurring(false)
    }, [masterWeeks.length])

    useEffect(() => {
        if (!masterWeeks)
            return;
        if (!selectedMasterWeeksIDs || selectedMasterWeeksIDs.length < 1)
            return;
    }, [masterWeeks, selectedMasterWeeksIDs])

    useEffect(() => {
        if (!selectedMasterWeeksIDs || selectedMasterWeeksIDs.length < 1)
            return;

        fetcher.get(`schedule/patient/mastersdays/${patientId}?scheduleIds=${selectedMasterWeeksIDs.join(',')}`).then((response) => {
            setWeekData(response?.data?.data);
        })
    }, [selectedMasterWeeksIDs])

    useEffect(() => {
        if (!masterWeeks || masterWeeks.length < 1)
            return;

        const minFromStr = getEarliestDate(masterWeeks.filter(s => !!s.fromDate && selectedMasterWeeksIDs.some(id => s.id === id)).map(s => s.fromDate || new Date())) ?? new Date();
        const minFrom = new Date(minFromStr);
        //const maxTo = getLatestDate(schedules.filter(s => !!s.toDate).map(s => s.toDate || new Date()));

        console.log(todayDT);
        console.log(minFrom);

        const from = minFrom.getTime() < todayDT.getTime() ? todayDT : minFrom;
        console.log(from);
        set_fromDate(dayjs(from).format('YYYY-MM-DD'));
    }, [selectedMasterWeeksIDs])

    return (
        <Box style={{ maxWidth: 'none !important' }} sx={{
            borderTop: `0px solid ${app_light}`,  // Top border 20px
            borderLeft: '0px solid ' + app_light, // Left border 0.5px
            borderRight: '0px solid ' + app_light, // Right border 0.5px
            borderBottom: '0px solid',  // Bottom border 0px
            padding: '10px 0px 0px 0px',
            backgroundColor: app_xx_light_bg
        }}>
            {<div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }} >

                <Box sx={{ width: '100%', padding: '0px', minWidth: '1200px', minHeight: '50px' }}>
                    <ReactLoader isLoading={fetcher.isLoading} />
                    <Grid container spacing={1} sx={{ marginBottom: '10px' }}>
                        <Grid item xs={2.2} sx={{ fontWeight: 500 }}>
                            <Grid container>
                                <Grid item xs={2}>
                                    <IconButton
                                        sx={{ padding: '5px 10px' }}
                                        disabled={!masterWeeks.length}
                                        //onClick={(e: any) => { set_staffingAs(!!isRecurring ? 'perm' : 'temp') }}>
                                        onClick={(e: any) => {
                                            set_expandRecurring(!expandRecurring)
                                        }}>
                                        {expandRecurring ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
                                    </IconButton>
                                </Grid>
                                <Grid item xs={10}>
                                    <Title sx={{ margin: '8px', color: !masterWeeks?.length ? COLORS.disabled : COLORS.black }} fontSize={'17px'}>
                                        Weekly Schedule
                                    </Title>
                                </Grid>
                            </Grid>
                        </Grid>
                        {!!expandRecurring && <Grid item xs={1.6}>
                            <DateEditField
                                label={"From"}
                                minDate={todayDT}
                                dateValue={fromDate}
                                handleChange={(value: string) => {
                                    console.log(value);
                                }}
                            />
                        </Grid>}
                        {!!expandRecurring && <Grid item xs={1.6}>
                            {/* <DateEditField
                                label={"To"}
                                dateValue={toDate}
                                handleChange={(value: string) => {
                                    console.log(value);
                                }}
                            /> */}
                        </Grid>}
                        <Grid item xs={3.6}>

                        </Grid>
                        {!!expandRecurring && <Grid item xs={2.8}>
                            <Box>
                                <FormControl fullWidth>
                                    <InputLabel sx={{ fontSize: '15px' }}>Select Schedule</InputLabel>
                                    <Select
                                        multiple
                                        label={'Select Schedule'}
                                        inputProps={{ shrink: true }}
                                        value={selectedMasterWeeksIDs}
                                        sx={{
                                            fontSize: '15px',
                                            height: '35px', // Makes the input field smaller
                                            padding: '3px 0px 3px 3px', // Adjust padding for smaller input
                                        }}
                                        onChange={(e) => { setSelectedMasterWeeksIDs(e.target.value as []) }}>
                                        {masterWeeks.map((schedule: PatientWeekScheduleDto, index: number) => (
                                            <MenuItem key={index} value={schedule.id}>
                                                {dayjs(schedule.fromDate).format('MM/DD/YY')} - {!!schedule.toDate ? dayjs(schedule.toDate).format('MM/DD/YY') : 'Ongoing'}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>}
                    </Grid>
                    {!!expandRecurring && <Grid container width={'100%'} spacing={0.5} direction="row">
                        {weekData.map((day, i) => (
                            <Grid item xs={1.7142} key={i}>
                                {/* <Card sx={{ backgroundColor: 'grey.100', marginBottom: .25, padding: 0 }}>
                                    <CardContent
                                        sx={{
                                            padding: '2px 2px 2px 3px',
                                            '&:last-child': { paddingBottom: '2px' }
                                        }}>
                                        <Typography color="primary" variant="caption" sx={{ display: 'block' }}>
                                            <div>
                                                {day.dayOfWeek}{' '}{dateTime.getShortMonthAndDay(day.dayDate)}
                                            </div>
                                        </Typography>
                                    </CardContent>
                                </Card> */}
                                {!day?.masters?.length ?
                                    <div>
                                        No master visits
                                    </div> :
                                    day.masters.map((master, j) => {
                                        const visit = day.visits.find(v => v.masterDayId === master.masterDayId);

                                        return (
                                            <MasterVisitCard
                                                key={j} master={master} visit={visit} showScheduledInfo={showScheduledInfo}
                                                selected={selectedMasterDaysIDs.some(s => s === master.masterDayId)}
                                                handleClick={() => !!toggleItem && toggleItem(master.masterDayId.toString())}
                                            />
                                        )
                                    })}
                                {!!showScheduledInfo && day.visits.filter((visit: any) => !!visit.masterDayId).map((visit, j) => (
                                    <VisitCard visit={visit} key={j} />
                                ))}
                            </Grid>
                        ))}
                    </Grid>}
                </Box>
            </div>}
        </Box >
    );
};

export default PatientWeekSeek;