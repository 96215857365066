import React, { useState } from "react";
import { Tooltip, Divider } from '../../../lib/MUI/mui.components';
import { DivContainer, FlexAlignCenter, FlexBetween, Text, Title } from "../../../assets/styles/styledComponents";
import Button from '../Button'
import {
    DataGridPro, GridOverlay, GridToolbar, GridToolbarContainer, GridRowModes, GridToolbarExport, GridToolbarDensitySelector,
    GridToolbarFilterButton, GridToolbarColumnsButton, getGridStringOperators, getGridSingleSelectOperators, GRID_CHECKBOX_SELECTION_COL_DEF
}
    from "@mui/x-data-grid-pro";
import FeaturedIcon from "../../common/FeaturedIcon";
import { dateTime } from '../../../helper/datetime';
import { datagridStyle } from '../../../data/GridColumnDefinitions';

function CustomLoadingOverlay(props: any) {
    return (
        <GridOverlay>
            <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                {
                    props.LoadingOverlay
                }
            </div>
        </GridOverlay>
    );
}
const DataGrid: any = (props: any) => {
    const {
        onOpen,
        onEdit,
        onDelete,
        onAssignUser,
        onChangePassword,
        onClick,
        hideToolbarIconText,
        title,
        headerMessage,
        showAdd,
        data,
        changeScheduleType,
        showEdit,
        handleEditClick,
        handleAddClick,
        quickFilters,
        autoHeight,
        disablePagination,
        rowSelection, // 'none', 'single', 'multi'
        rowSelectionOnClick = true,
        selectedRows,
        setSelectedRows,
        options,
        treeData,
        getTreeDataPath,
        LoadingOverlay
    } = props

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const actionColumns = {
        edit: {
            field: "edit",
            enableTooltip: false,
            icon: "Edit",
            flex: .02,
            show: (params: any) => {
            },
            handleClick: (event: any, params: any) => {
                props.onOpen?.(params);
                props.onEdit?.(params);
            },
            sortable: false,
        },
        more: {
            field: "moreIcon", disableColumnMenu: true, enableTooltip: false, icon: "MoreVert", sortable: false, maxWidth: 50, type: 'icon',
            handleClick: (event: any, params: any) => {
                props.onDelete(params);
            },
        },
        delete: {
            field: "delete",
            enableTooltip: false,
            flex: .02,
            icon: "Delete",
            sortable: false,
            show: (params: any) => {

            },
            handleClick: (event: any, params: any) => {
                props.onDelete(params);
            },
        },
        assign: {
            field: "assignUser",
            label: "Assign User",
            enableTooltip: false,
            icon: "Person",
            flex: .02,
            sortable: false,
            handleClick: (event: any, params: any) => {
                onAssignUser(params);
            },
            show: (params: any) => {

            },
        },
        enrollment: {
            field: "enrollment",
            label: "Enrollment",
            enableTooltip: false,
            flex: .02,
            icon: "Article",
            sortable: false,
            handleClick: (event: any, params: any) => {
                props.onOpen(params);
                props.onEdit(params);
                event.stopPropagation();
            },
            show: (params: any) => {

            },
        },
        changePassword: {
            field: "changePassword",
            disableClickEventBubbling: true,
            show: (params: any) => { },
            icon: "VpnKey",
            flex: .02,
            sortable: false,
            handleClick: (event: any, params: any) => {
                onChangePassword(params);
            },
        },
        accessLevel: {
            field: "creatorRole",
            label: "Restricted",
            show: (params: any) => params.row.creatorRole,
            icon: "Block",
            flex: .02,
            sortable: false,
        }
    }

    const getFeaturedColumns = () => {
        return getColumns().map((column: any, index: number) => FeaturedColumn(column));
    }

    const getColumns = () => {
        return props.data?.columns?.map((column: any) => {
            if (column.type === 'action') {
                return actionColumns[column.field as keyof typeof actionColumns];
            }
            return column;
        })
    };

    const getSingleSelectFilters = () => {
        let singleSelectOp = getGridSingleSelectOperators();
        let stringOp = getGridStringOperators().filter(x => x.value === 'isEmpty' || x.value === 'isNotEmpty');
        let filters = [...singleSelectOp, ...stringOp]
        return filters;
    }

    const getNavUrl = (navUrl: string, row: any) => {
        if (!navUrl || !row)
            return;
        let paths = navUrl.split('/').map(x => {
            if (x.indexOf('{') < 0)
                return x;
            let fieldName = x.substring(
                x.indexOf("{") + 1,
                x.lastIndexOf("}")
            );
            return row[fieldName];
        });
        return paths.join('/');
    }

    const FeaturedColumn = (column: any) => {
        if (!column || !column.field) return column;

        const fieldName = column.field;
        const styles = {
            textAlign: "left",
            maxWidth: "100%",
            display: "inline-block",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
        };
        const { field, label, handleClick, icon, show, navUrl, hide, type, getStringValue, getTooltipTitle, getIcon, fontSize, renderCell } = column;
        const headerName = column.headerName || " ";
        const width = column.width || 110;
        const flex = column.flex || 0.08;
        const sortable = column.sortable ?? true;
        const enableTooltip = column.enableTooltip ?? (icon ? true : false);
        const showDetailsInDialog = column.enableDialog;
        const disableClickEventBubbling = column.disableClickEventBubbling ?? true;

        let customColumn = {
            ...column,
            field: field,
            headerName: headerName,
            description: column.description ?? headerName,
            label: label,
            width: width,
            flex: flex,
            hide: hide,
            sortable: sortable,
            handleClick,
            icon,
            getIcon,
            show,
            navUrl,
            renderCell,
            enableTooltip: enableTooltip,
            getTooltipTitle,
            disableClickEventBubbling: disableClickEventBubbling,
            onCellClick: showDetailsInDialog ? () => { setIsDialogOpen(true) } : () => { },
        };

        if (column.navUrl) customColumn.cellClassName = "style-url--cell";

        if (type === 'singleSelect') {
            customColumn.valueOptions = props.valueOptionsLists
                && props.valueOptionsLists[field]
                && Array.isArray(props.valueOptionsLists[field]['values']) ?
                props.valueOptionsLists[field]['values'] :
                [];
            customColumn.filterOperators = getSingleSelectFilters();
        }

        if (!renderCell) {
            customColumn.renderCell = (params: any) => {
                const onClick = (event: any) => {
                    if (handleClick && typeof handleClick === "function") {
                        handleClick(event, params);
                        event.stopPropagation();
                    }
                    else if (navUrl) {
                        window.open(getNavUrl(navUrl, params.row), "_blank");
                        event.stopPropagation();
                    }
                };
                const tooltipShowQuery = `${params.row.id}-${field}`;
                let iconValue = !!getIcon ? getIcon(params.row) : icon;
                return (
                    <Tooltip
                        placement="top"
                        title={enableTooltip ? (
                            <span>
                                {params.row[field]}
                            </span>) : ''}
                    >
                        <DivContainer
                            style={{ ...styles }}
                            onClick={onClick}
                        >
                            {
                                !!iconValue ?
                                    <FeaturedIcon
                                        fontSize={fontSize || "small"}
                                        params={params}
                                        icon={iconValue}
                                        field={field}
                                        onClick={onClick}
                                        disableClick={!handleClick && !navUrl}
                                        screen={props.data.screen}
                                    /> :
                                    <Text>
                                        {params.row[fieldName]}
                                    </Text>
                            }
                        </DivContainer>
                    </Tooltip>
                );

            };
        }

        if (type === 'date') {
            customColumn.renderCell = (params: any) => dateTime.getUSAFormattedDateString(params.row[fieldName])
        }

        if (type === 'dateTime') {
            customColumn.renderCell = (params: any) => dateTime.getFormattedDateTime(params.row[fieldName])
        }

        return customColumn;
    }

    function CustomNoRowsOverlay() {
        // console.debug('CustomNoRowsOverlay');
        return (
            <GridOverlay>
                <div style={{ top: '50%', left: '50%' }}>
                    {
                        //props.children ||
                        <div>
                            {props.options?.noRowsOverlayText}
                        </div>
                    }
                </div>
            </GridOverlay>
        );
    }

    // create component
    const CustomToolbar = () => {
        const [isDialogOpen, setIsDialogOpen] = useState(false);
        const [selectedRow, setSelectedRow] = useState<any>(null);

        // let's wrap up
        return (
            <GridToolbarContainer sx={{ justifyContent: 'space-between' }}>
                <FlexAlignCenter padding="0px 0px 0px 10px">
                    {title &&
                        <Title>
                            {title}
                        </Title>
                    }
                </FlexAlignCenter>
                <DivContainer>
                    {headerMessage}
                </DivContainer>
                <FlexAlignCenter>
                    {props.options?.toolbarComponent}
                    <Divider sx={{ margin: '10px' }} orientation="vertical" variant="middle" flexItem />
                    {showEdit &&
                        <>
                            <FeaturedIcon
                                onClick={handleEditClick}
                                className="icon"
                                size="small"
                                color="primary"
                                icon="Edit"
                            />
                            <Divider sx={{ margin: '10px' }} orientation="vertical" variant="middle" flexItem />
                        </>
                    }
                    {!!props?.options?.addRow &&
                        <>
                            <Button
                                onClick={() => props?.options?.addRow?.onClick()}
                                label={props.options.addRow.label}
                            />
                            <Divider sx={{ margin: '10px' }} orientation="vertical" variant="middle" flexItem />
                        </>
                    }

                    {!props.options?.hideColumns &&
                        <Tooltip title="Show Columns">
                            <GridToolbarColumnsButton
                                style={{ color: "primary" }}
                                sx={props.options?.hideToolbarIconText ? datagridStyle().hideToolbarText : {}}
                                placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}
                            />
                        </Tooltip>
                    }
                    {!props.options?.hideFiltering && <Tooltip title="Show Filter">
                        <GridToolbarFilterButton
                            style={{ color: "primary" }}
                            sx={props.options?.hideToolbarIconText ? datagridStyle().hideToolbarText : {}}
                            placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}
                        />
                    </Tooltip>
                    }
                    {!props.options?.hideExport &&
                        <Tooltip title="Export">
                            <GridToolbarExport
                                style={{ color: "primary" }}
                                sx={props.options?.hideToolbarIconText ? datagridStyle().hideToolbarText : {}}
                                printOptions={{
                                    hideToolbarIconText: true,
                                    hideToolbar: true,
                                    hideFooter: true,
                                }}
                                csvOptions={{
                                    fileName: typeof props.options?.exportFileName === 'function' ? props.options?.exportFileName() : 'data-export',
                                    //delimiter: ';',
                                }}
                            />
                        </Tooltip>
                    }
                </FlexAlignCenter>
            </GridToolbarContainer>
        );
    }

    const handleRowClick = (param: any, event: React.MouseEvent) => {
        if (setSelectedRows === undefined) return;

        const newSelectedRows = !!selectedRows ? [...selectedRows] : [];

        if (!!event.ctrlKey || !!event.metaKey) {
            if (selectedRows.includes(param.row.id as number)) {
                const index = newSelectedRows.indexOf(param.row.id as number);
                newSelectedRows.splice(index, 1);
            } else {
                newSelectedRows.push(param.row.id as number);
            }

            setSelectedRows(newSelectedRows);
        }
        else if (!!rowSelectionOnClick) {
            setSelectedRows([param.row.id as number]);
        } else {
            props.onRowClick?.(param);
        }
    };
    return (
        <DivContainer>
            <DataGridPro
                {...props}
                sx={
                    (theme) => (datagridStyle(theme, props).root)
                }
                rows={props.data?.rows?.length > 0 && props.data?.rows?.map((row: any, index: string) => {
                    return {
                        ...row,
                        key: row.key || row.id || index,
                        id: row.id || row.key || index,
                    }
                })}
                treeData={treeData}
                getTreeDataPath={getTreeDataPath}
                columns={getFeaturedColumns()}
                onRowEditStart={props.handleRowEditStart}
                onRowEditStop={props.handleRowEditStop}
                headerHeight={props.options?.headerHeight || 40}
                rowHeight={props.options?.rowHeight || 36}
                hideFooterSelectedRowCount={true}
                hideFooterRowCount={options.hideFooterRowCount}
                hideFooter={options.hideFooter}
                // getDetailPanelContent={({ row }) => <div>Row ID: {row.id}</div>}
                getDetailPanelContent={props.getDetailPanelContent}
                detailPanelExpandedRowIds={props.detailPanelExpandedRowIds}
                onDetailPanelExpandedRowIdsChange={props.onDetailPanelExpandedRowIdsChange}
                pageSize={props.options?.pageSize}
                autoPageSize={props.options?.pageSize ? false : true}
                pagination={props.options?.pagination ?? true}
                paginationMode={props.options?.paginationMode ?? 'client'}
                onPageChange={props.options?.onPageChange}
                onPageSizeChange={props.options?.onPageSizeChange}
                loading={props.loading}
                onCellClick={props.onRowClick}
                getRowClassName={props.getRowClassName}

                components={{
                    Toolbar: (!options?.hideToolbarHeader && CustomToolbar) || undefined,
                    NoRowsOverlay: (!!options?.noRowsOverlayText && CustomNoRowsOverlay) || undefined,
                    NoResultsOverlay: (!!options?.noRowsOverlayText && NoResultsOverlay) || undefined,
                    LoadingOverlay: LoadingOverlay
                }}
                onRowClick={handleRowClick}
                componentsProps={{
                    panel: {},
                }}
            />
        </DivContainer>
    );
}

DataGrid.defaultProps = {
    height: "70vh",
};

const NoRowsOverlay = () => {
    return (
        <div style={{ height: "100%", alignItems: "center", justifyContent: "center" }}>
            No rows
        </div>
    );
}
const NoResultsOverlay = () => {
    return (
        <div style={{ height: "100%", alignItems: "center", justifyContent: "center" }}>
            No results
        </div>
    );
}

export default DataGrid




