import React from 'react';
import { Chip, styled } from '@mui/material';
import { alpha, Theme, PaletteColor } from '@mui/material/styles';

type ThemeColor = 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success';
type ColorShade = 'light' | 'main' | 'dark';

type ColorType = 'success' | 'error' | 'warning';


const colorMap = {
  success: 'green',
  error: 'red',
  warning: 'orange'
} as const;

const generateGradient = (ranges: TimeRange[]): string => {
  const maxEnd = Math.max(...ranges.map(r => r.end));
  
  const gradientStops = ranges.map(range => {
    const startPercent = (range.start / maxEnd * 100).toFixed(2);
    const endPercent = (range.end / maxEnd * 100).toFixed(2);
    const color = colorMap[range.color as ColorType] || colorMap.success;
    
    return `${color} ${startPercent}%, ${color} ${endPercent}%`;
  });
  
  const str: string = `linear-gradient(to right, ${gradientStops.join(', ')})`;
  return str;
};
export interface TimeRange {
  start: number;
  end: number;
  color?: string | `${ThemeColor}.${ColorShade}`;
}

interface TimeChipProps {
  timeRange: string;
  isClash?: boolean;
  isPerm?: boolean;
  upperRanges?: TimeRange[];
  lowerRanges?: TimeRange[];
  borderRanges?: TimeRange[];  
  bottomRanges?: TimeRange[];  
  totalMinutes?: number;
}

const CHIP_HEIGHT = 24; // height of small chip
const BORDER_RADIUS = CHIP_HEIGHT / 2; // MUI Chip uses half height for border radius

const ChipContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'inline-flex',
  height: CHIP_HEIGHT,
  borderRadius: BORDER_RADIUS,
  '& .MuiChip-root': {
    position: 'relative',
    zIndex: 1,
    backgroundColor: 'transparent'
  }
}));

const BackgroundContainer = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  borderRadius: BORDER_RADIUS,
  overflow: 'hidden'
});

interface RangeProps {
  startPercent: number;
  rangeWidth: number;
  isFirst: boolean;
  isLast: boolean;
  isUpper: boolean;
  height?: string;
}

const BackgroundRange = styled('div')<RangeProps>(({
  startPercent,
  rangeWidth,
  isFirst,
  isLast,
  isUpper,
  height = '50%'
}) => ({
  position: 'absolute',
  left: `${startPercent}%`,
  width: `${rangeWidth}%`,
  height: height ?? '50%',
  top: isUpper ? 0 : `${height ? 100 - Number(height.replace('%', '')) : 50}%`,
  borderTopLeftRadius: isFirst && isUpper ? BORDER_RADIUS : 0,
  borderBottomLeftRadius: isFirst && !isUpper ? BORDER_RADIUS : 0,
  borderTopRightRadius: isLast && isUpper ? BORDER_RADIUS : 0,
  borderBottomRightRadius: isLast && !isUpper ? BORDER_RADIUS : 0
}));

const TimeChip: React.FC<TimeChipProps> = ({
  timeRange,
  isClash = false,
  isPerm = false,
  upperRanges = [],
  lowerRanges = [],
  bottomRanges = [],
  totalMinutes = 1440
}) => {
  const minutesToPercent = (minutes: number): number =>
    Math.max(0, Math.min(100, (minutes / totalMinutes) * 100));

  const sortedUpperRanges = [...upperRanges].sort((a, b) => a.start - b.start);
  const sortedLowerRanges = [...lowerRanges].sort((a, b) => a.start - b.start);
  const sortedBottomRanges = [...bottomRanges]
    .map((r: TimeRange) => ({start: r.start, end: r.end, color: r.color === 'error' ? '#fc2323' : ''})) //'#22d029'
    .sort((a, b) => a.start - b.start);

  const getChipColor = (): ThemeColor => {
    if (isClash) {
      return isPerm ? 'error' : 'warning';
    }
    return 'success';
  };

  const getDefaultRangeColor = (theme: Theme): string => {
    if (isClash) {
      return isPerm
        ? alpha(theme.palette.error.main, 0.1)
        : alpha(theme.palette.warning.main, 0.1);
    }
    return alpha(theme.palette.success.main, 0.1);
  };

  const resolveThemeColor = (colorString: string, theme: Theme): string | null => {
    const [paletteName, shade] = colorString.split('.') as [ThemeColor, ColorShade];

    if (
      paletteName in theme.palette &&
      theme.palette[paletteName] &&
      typeof theme.palette[paletteName] === 'object'
    ) {
      const paletteColor = theme.palette[paletteName] as PaletteColor;
      return alpha(paletteColor[shade] || paletteColor.main, 0.1);
    }

    return null;
  };

  const resolveRangeColor = (range: TimeRange, theme: Theme): string => {
    if (!range.color) return getDefaultRangeColor(theme);

    if (range.color.startsWith('#') ||
      range.color.startsWith('rgb') ||
      range.color.startsWith('hsl')) {
      return range.color;
    }

    const themeColor = resolveThemeColor(range.color, theme);
    if (themeColor) return themeColor;

    return getDefaultRangeColor(theme);
  };

  return (
    <ChipContainer>
      <BackgroundContainer>
        {sortedUpperRanges.map((range, index) => {
          const startPercent = minutesToPercent(range.start);
          const endPercent = minutesToPercent(range.end);
          const width = endPercent - startPercent;

          return (
            <BackgroundRange
              key={`upper-${index}`}
              startPercent={startPercent}
              rangeWidth={width}
              isFirst={index === 0 && startPercent === 0}
              isLast={index === sortedUpperRanges.length - 1 && endPercent === 100}
              isUpper={true}
              height='83%'
              sx={theme => ({
                backgroundColor: resolveRangeColor(range, theme)
              })}
            />
          );
        })}

        {sortedLowerRanges.map((range, index) => {
          const startPercent = minutesToPercent(range.start);
          const endPercent = minutesToPercent(range.end);
          const width = endPercent - startPercent;

          return (
            <BackgroundRange
              key={`lower-${index}`}
              startPercent={startPercent}
              rangeWidth={width}
              isFirst={index === 0 && startPercent === 0}
              isLast={index === sortedLowerRanges.length - 1 && endPercent === 100}
              isUpper={false}
              height='17%'
              sx={theme => ({
                backgroundColor: resolveRangeColor(range, theme),
              })}
            />
          );
        })}
      </BackgroundContainer>
      {!!upperRanges?.length && <Chip
      //label={sortedLowerRanges.map(r => `${r.start}-${r.end}-${r.color}`).join("|")}
      label={
        <div style={{ position: 'relative', width: '100%' }}>
          <span style={{ padding: '0 12px', display: 'block' }}>
            {timeRange}
          </span>
          {bottomRanges?.filter(r => r.color == 'error').map((range, index) => {
            const startPercent = minutesToPercent(range.start);
            const endPercent = minutesToPercent(range.end);
            // red line span
            return (
              <span
                key={`strike-${index}`}
                style={{
                  position: 'absolute',
                  left: `${startPercent}%`,
                  width: `${endPercent - startPercent}%`,
                  height: '1px',
                  backgroundColor: 'red',
                  top: '78%',
                  transform: 'translateY(-50%)'
                }}
              />
            );
          })}
        </div>
      }
        variant="outlined"
        size="small"
        sx={{
            height: CHIP_HEIGHT,
            minWidth: '130px',
            maxWidth: '130px',
            background: 'transparent',
            border: 'none', // Remove default border
            position: 'relative',
            '&::before': {
                content: '""',
                position: 'absolute',
                inset: 0,
                padding: '1px', // Border width
                borderRadius: '16px', // Match Chip's border radius
                background: generateGradient(sortedUpperRanges),
                WebkitMask: 
                    'linear-gradient(#fff 0 0) content-box, ' +
                    'linear-gradient(#fff 0 0)',
                WebkitMaskComposite: 'xor',
                maskComposite: 'exclude',
            },
            '& .MuiChip-label': {
                fontSize: '0.75rem',
                padding: 0
            }
        }}
    />}
    </ChipContainer>
  );
};

export default TimeChip;