import React, { useState, useEffect } from 'react';
import { Box, Typography, styled, keyframes } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { GridOverlay } from '@mui/x-data-grid-pro';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RecentActorsIcon from '@mui/icons-material/RecentActors';

// Keyframes for continuous animations
const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

const bounce = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
`;

// Styled components
const LoaderContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.6) !important',
  zIndex: 9999,
}));

const AnimatedIcon = styled(Box)(({ theme }) => ({
  animation: `${pulse} 2s infinite`,
  display: 'inline-flex',
  alignItems: 'center',
  '& .MuiSvgIcon-root': {
    fontSize: '2rem',
  },
}));

const SpinningLoader = styled(AutorenewIcon)({
  animation: `${spin} 1.5s linear infinite`,
});
const BouncingSearch = styled(SearchIcon)({
  animation: `${bounce} 1s infinite`,
});
const BouncingCalendar = styled(CalendarMonthIcon)({
  animation: `${bounce} 1s infinite`,
});
const BouncingAvailability = styled(RecentActorsIcon)({
  animation: `${bounce} 1s infinite`,
});
const AnimatedText = styled(Typography)(({ theme }) => ({
  animation: `${pulse} 1.4s infinite`,
  color: theme.palette.primary.main,
}));

interface LoadingState {
  text: string;
  icon?: 'search' | 'loader' | 'calendar' | 'availability';
  orderN: number;
  durationSec: number;  // -1 for indefinite
}

interface LoaderProps {
  text?: string;
  icon?: 'search' | 'loader';
  isOverlay?: boolean;
  loadingStates?: LoadingState[];
  rotateXtimes?: number;
}

const AnimatedLoader: React.FC<LoaderProps> = ({
  text = '',
  icon = '',
  isOverlay = false,
  loadingStates = [],
  rotateXtimes = 1
}) => {
  const [currentStateIndex, setCurrentStateIndex] = useState(0);
  const [rotationCount, setRotationCount] = useState(0);
  const [currentText, setCurrentText] = useState(text);
  const [currentIcon, setCurrentIcon] = useState(icon);

  useEffect(() => {
    if (!loadingStates.length) return;

    // Sort states by orderN
    const sortedStates = [...loadingStates].sort((a, b) => a.orderN - b.orderN);

    const moveToNextState = () => {
      setCurrentStateIndex(prevIndex => {
        const nextIndex = prevIndex + 1;

        // Check if we've completed a rotation
        if (nextIndex >= sortedStates.length) {
          setRotationCount(prev => prev + 1);
          return 0;
        }

        return nextIndex;
      });
    };

    const currentState = sortedStates[currentStateIndex];
    setCurrentText(currentState.text);
    setCurrentIcon(currentState.icon || icon);

    // If we've reached the rotation limit, stay at the last state
    if (rotateXtimes !== undefined && rotationCount >= rotateXtimes) {
      return;
    }

    // If duration is -1, don't set up the next transition
    if (currentState.durationSec === -1) {
      return;
    }

    const timer = setTimeout(moveToNextState, currentState.durationSec * 1000);
    return () => clearTimeout(timer);
  }, [currentStateIndex, rotationCount, loadingStates, rotateXtimes]);

  const Content = (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 2,
      p: 2,
    }}>
      <AnimatedIcon>
        {currentIcon === 'search' ? (
          <BouncingSearch color="primary" sx={{ height: '25px' }} />
        ) : currentIcon === 'calendar' ? (
          <BouncingCalendar color="primary" sx={{ height: '25px' }} />
        ) : currentIcon === 'availability' ? (
          <BouncingAvailability color="primary" sx={{ height: '25px' }} />
        ) : (
          <SpinningLoader color="primary" sx={{ height: '25px' }} />
        )}
      </AnimatedIcon>

      <AnimatedText variant="h6" sx={{ fontWeight: 400, fontSize: 16 }}>
        {currentText}
      </AnimatedText>
    </Box>
  );

  if (isOverlay) {
    return (
      <LoaderContainer>
        {Content}
      </LoaderContainer>
    );
  }

  return Content;
};

// DataGrid LoadingOverlay component with predefined states
export const FindingMatchesLoadingOverlay = () => {
  const defaultStates: LoadingState[] = [
    { text: 'Finding matches . . .', icon: 'search', orderN: 1, durationSec: 2 },
    { text: 'Checking conflicts . . .', icon: 'calendar', orderN: 2, durationSec: 2 },
    { text: 'Checking availability . . .', icon: 'availability', orderN: 3, durationSec: 2 },
    { text: 'Preparing results . . .', icon: 'loader', orderN: 4, durationSec: -1 },
  ];

  return (
    <GridOverlay>
      <AnimatedLoader
        isOverlay
        loadingStates={defaultStates}
        rotateXtimes={2}
      />
    </GridOverlay>
  );
};

// Standalone component
export const StandaloneLoader = () => {
  return <AnimatedLoader text="Finding matches . . ." icon="search" />;
};

export default AnimatedLoader;