import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import EditPatientScheduleModal from '../../../../features/patients/EditPatientScheduleModal';
import PatientScheduleList from '../../../../features/patients/PatientScheduleList';
import useFetcher from '../../../../hooks/useFetcher';
import Loader from '../../Loader';

const Schedule = () => {
  const { id: paramId } = useParams<{ id: string }>();
  const [selectedScheduleId, setSelectedScheduleId] = useState<number | undefined>();
  const scheduleFetcher = useFetcher();
  const newScheduleFetcher = useFetcher();
  const editScheduleFetcher = useFetcher();
  const [rowUpdated, set_rowUpdated] = useState<boolean>(false);

  const handleScheduleSelect = (scheduleId: number | undefined) => {
    setSelectedScheduleId(scheduleId);
  };

  return (
    <Box>
      <Loader isLoading={newScheduleFetcher.isLoading || scheduleFetcher.isLoading || editScheduleFetcher.isLoading} />
      <Grid container>
        <Grid item xs={3}>
          <PatientScheduleList
            patientId={paramId || ''}
            onScheduleSelect={handleScheduleSelect}
            scheduleFetcher={scheduleFetcher}
            newScheduleFetcher={newScheduleFetcher}
            rowUpdated={rowUpdated}
          />
        </Grid>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={8}>
          <EditPatientScheduleModal
            scheduleId={selectedScheduleId}
            patientId_prop={paramId}
            onSaved={() => set_rowUpdated(!rowUpdated)}
            editScheduleFetcher={editScheduleFetcher}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Schedule;