import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Grid,
  TextField,
  IconButton,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import useFetcher, { FetcherHook } from "../../hooks/useFetcher";
import useToast from "../../hooks/useToast";
import { weekDays } from "../../data/constants";
import { AccessTime, CalendarToday } from "@mui/icons-material";
import { LocalizationProvider, MobileDatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Title } from "../../assets/styles/styledComponents";
import { dateTime } from "../../helper/datetime";
import dayjs from "dayjs";
import ReactLoader from "../../components/common/ReactLoader";

interface PatientWeeklySchedule {
  id: number;
  from: string;
  to: string;
  days: { day: string; from: string; to: string }[];
}

interface EditPatientScheduleModalProps {
  scheduleId?: number;
  onSaved?: () => void;
  initialData?: PatientWeeklySchedule;
  patientId_prop?: string;
  editScheduleFetcher: FetcherHook;
}

const EditPatientScheduleModal: React.FC<EditPatientScheduleModalProps> = ({
  scheduleId,
  onSaved,
  initialData,
  patientId_prop,
  editScheduleFetcher,
}) => {
  const { id: paramId } = useParams<{ id: string }>();
  const { post, get, isLoading } = editScheduleFetcher;
  const { showError, showSuccess } = useToast();
  const [patientId, setPatientId] = useState<string>(patientId_prop || paramId || '-1');

  const [schedule, setSchedule] = useState<PatientWeeklySchedule>({
    id: -1,
    from: "",
    to: "",
    days: weekDays.map((day: any) => ({ day, from: "", to: "" })),
  });

  useEffect(() => {
    if (initialData) {
      setSchedule(initialData);
    }
  }, [initialData]);

  useEffect(() => {
    if (!!scheduleId) {
      get(`schedule/patient/${scheduleId}`)
        .then((response: any) => {
          const data = response?.data?.data;// as PatientWeeklySchedule;
          if (!!data) {
            const fetchedSchedule: PatientWeeklySchedule = {
              id: data.id,
              from: data.fromDate,
              to: data.toDate,
              days: data.days.map((day: any) => ({
                day: day.day,
                from: day.from,
                to: day.to,
              })),
            };
            setSchedule(fetchedSchedule);
          }
        })
        .catch((error: any) => {
          console.error("Failed to fetch the patient schedule:", error);
        });
    }
  }, [scheduleId]);

  const handleChange = (field: keyof PatientWeeklySchedule, value: string) => {
    console.log(value);
    setSchedule((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleDayChange = (day: string, field: "from" | "to", value: string) => {
    setSchedule((prev) => ({
      ...prev,
      days: prev.days.map((d) =>
        d.day === day ? { ...d, [field]: value } : d
      ),
    }));
  };

  const handleSave = async () => {
    if (!schedule.from) {
      showError("Start date is required.");
      return;
    }

    try {
      const payload = { ...schedule, patientId: parseInt(patientId || "0"), fromDate: schedule.from, toDate: schedule.to };
      await post(`/schedule/patient/save`, payload);
      showSuccess("Schedule saved successfully.");
      if (!!onSaved)
        onSaved();
    } catch (err) {
      showError("Failed to save schedule.");
    }
  };

  return (
    !scheduleId || scheduleId < 1 ? 
    <Typography variant="h6" align="center" sx={{ my: 2 }}>
      Select a schedule from the list
    </Typography> :
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box sx={{ mt: 2, width: '800px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Title fontSize="20px">Patient Weekly Schedule</Title>
            </Grid>
            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TextField
                  label="Begin"
                  type="date" // Use HTML date input format
                  value={schedule.from || ""}
                  onChange={(e: any) => handleChange("from", e.target.value)}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => {
                          document.getElementById("begin-picker")?.click();
                        }}
                      >
                        <CalendarToday fontSize="small" />
                      </IconButton>
                    ),
                  }}
                  sx={{
                    "& input::-webkit-calendar-picker-indicator": {
                      display: "none",
                    },
                    "& input[type='date']": {
                      "-webkit-appearance": "none",
                      "-moz-appearance": "none",
                      appearance: "none",
                    },
                  }}
                />
                <MobileDatePicker
                  value={schedule.from ? dayjs(schedule.from).format('YYYY-MM-DD') : null}
                  onChange={(value: any) => handleChange("from", dayjs(value).format('YYYY-MM-DD'))}
                  renderInput={(params: any) => (
                    <input
                      {...params.inputProps}
                      id="begin-picker"
                      style={{ display: "none" }} // Hide the picker input
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TextField
                  label="Through"
                  type="date" // Use HTML date input format
                  value={schedule.to || ""}
                  onChange={(e: any) => handleChange("to", e.target.value)}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => {
                          document.getElementById("through-picker")?.click();
                        }}
                      >
                        <CalendarToday fontSize="small" />
                      </IconButton>
                    ),
                  }}
                  sx={{
                    "& input::-webkit-calendar-picker-indicator": {
                      display: "none",
                    },
                    "& input[type='date']": {
                      "-webkit-appearance": "none",
                      "-moz-appearance": "none",
                      appearance: "none",
                    },
                  }}
                />
                <MobileDatePicker
                  value={schedule.to ? dayjs(schedule.to).format('YYYY-MM-DD') : null}
                  onChange={(value: any) => handleChange("to", dayjs(value).format('YYYY-MM-DD'))}
                  renderInput={(params: any) => (
                    <input
                      {...params.inputProps}
                      id="through-picker"
                      style={{ display: "none" }} // Hide the picker input
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={3}>
            </Grid>
            <Grid item xs={3}>
              <DialogActions>
                <Button variant="outlined" onClick={handleSave}>
                  Save
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            {schedule.days.map((day) => (
              <Grid container spacing={2} key={day.day} sx={{ mb: 2 }}>
                <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    component="span"
                    sx={{
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    {day.day}
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TextField
                      label="From"
                      type="time" // Use HTML time input format
                      value={day.from || ""}
                      onChange={(e: any) => handleDayChange(day.day, "from", e.target.value + ":00")}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onClick={() => {
                              document
                                .getElementById(`from-picker-${day.day}`)
                                ?.click();
                            }}
                          >
                            <AccessTime />
                          </IconButton>
                        ),
                      }}
                      sx={{
                        "& input::-webkit-calendar-picker-indicator": {
                          display: "none",
                        },
                        "& input[type='date']": {
                          "-webkit-appearance": "none",
                          "-moz-appearance": "none",
                          appearance: "none",
                        },
                      }}
                    />
                    <MobileTimePicker
                      value={day.from ? dayjs(day.from, "HH:mm:ss").toDate() : null}
                      onChange={(value: any) => handleDayChange(day.day, "from", dateTime.getTimeString(value, false) + ":00")}
                      renderInput={(params: any) => (
                        <input
                          {...params.inputProps}
                          id={`from-picker-${day.day}`}
                          style={{ display: "none" }} // Hide the picker input
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TextField
                      label="To"
                      type="time" // Use HTML time input format
                      value={day.to || ""}
                      onChange={(e: any) => handleDayChange(day.day, "to", e.target.value + ":00")}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onClick={() => {
                              document.getElementById(`to-picker-${day.day}`)?.click();
                            }}
                          >
                            <AccessTime />
                          </IconButton>
                        ),
                      }}
                      sx={{
                        "& input::-webkit-calendar-picker-indicator": {
                          display: "none",
                        },
                        "& input[type='date']": {
                          "-webkit-appearance": "none",
                          "-moz-appearance": "none",
                          appearance: "none",
                        },
                      }}
                    />
                    <MobileTimePicker
                      value={day.to ? dayjs(day.to, "HH:mm:ss").toDate() : null}
                      onChange={(value: any) => handleDayChange(day.day, "to", dateTime.getTimeString(value, false) + ":00")}
                      renderInput={(params: any) => (
                        <input
                          {...params.inputProps}
                          id={`to-picker-${day.day}`}
                          style={{ display: "none" }} // Hide the picker input
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            ))}
          </Box>
        </Box>

      </LocalizationProvider>
    </Box>
  );
};

export default EditPatientScheduleModal;
